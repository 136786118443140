import React, { useState, useContext, useEffect } from 'react'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'
import { Context } from '../context/Context'
import Air from '../images/air.webp'
import Clean from '../images/clean.webp'
import Emf from '../images/emf.webp'
import Water from '../images/water.webp'
import Sauna from '../images/sauna.webp'
import RedLight from '../images/redLight.webp'
import HBOT from '../images/hbot.webp'
import PowerPlate from '../images/powerPlate.webp'
import SiteAssets from '../images/siteAssets.webp'

export default function Categories(){

    const { category, setCategory, setProduct, setProducts, setHeroProduct, setMedia, setMediaIds, clickCategory } = useContext(Context)

    useEffect(() => {
        setProduct("")
        setProducts("")
        setHeroProduct()
        setMedia([])
        setMediaIds([])
    },[])

    return(
        <div className="categories">
            <h1 className="categories-title">Explore By Category</h1>
            <div className="categories-links">
                <Link to='/category' onClick={ () => clickCategory('air') } className="categories-link">
                    <img src={ Air } alt="Img of Sentry"/>
                    <h2>Air Sentry</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('clean') } className="categories-link">
                    <img src={ Clean } alt="Img of Clean Spray"/>
                    <h2>Clean</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('emf') } className="categories-link">
                    <img src={ Emf } alt="Img of EMF Qi"/>
                    <h2>EMF Protection</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('water') } className="categories-link">
                    <img src={ Water } alt="Img of Faucet Filter"/>
                    <h2>Hydrogen Water</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('sauna') } className="categories-link">
                    <img src={ Sauna } alt="Img of Sauna"/>
                    <h2>Infrared Sauna</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('redLight') } className="categories-link">
                    <img src={ RedLight } alt="Img of Red Light Pro"/>
                    <h2>Red Light</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('hbot') } className="categories-link">
                    <img src={ HBOT } alt="Img of HBOT"/>
                    <h2>HBOT</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('powerPlate') } className="categories-link">
                    <img src={ PowerPlate } alt="Img of Power Plate"/>
                    <h2>Power Plate</h2>
                </Link>
                <Link to='/category' onClick={ () => clickCategory('siteAssets') } className="categories-link">
                    <img src={ SiteAssets } alt="Img of ULH Logo"/>
                    <h2>Site Assets</h2>
                </Link>
            </div>
        </div>
    )
}
