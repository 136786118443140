import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../context/Context.js'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'

export default function CatPageImages(){

    const { media, images, categoryHeader } = useContext(Context)
    const [count, setCount] = useState(0)
    useEffect(() => {
        setCount(0)
        media.map(item => (item.specs === '4:1' || item.specs === '3:2' || item.specs === '3:1') && setCount(prev => prev += 1))
    },[media])

    return(
        <div className="catPageImages">
            <h1>{ categoryHeader === "Site Assets" ? `Graphics & Logos` : `Studio`}</h1>
            <div className="catPageImages-thumbnail-container">
                { media && images || <h1>Loading ...</h1> }
            </div>
            <Link to='/allImages' className="viewAll-button">View All ({count})</Link>
        </div>
    )
}