import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../context/Context.js'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'

export default function CatPageSocials(){

    const { media, socials, categoryHeader } = useContext(Context)
    const [count, setCount] = useState(0)
    useEffect(() => {
        setCount(0)
        media.map(item => item.imgType && (item.specs === '1:1' || item.specs === '9:16') && setCount(prev => prev += 1))
    },[media])

    return(
        <div className="catPageSocials">
            <h1>Lifestyle{ categoryHeader !== "Site Assets" ? ` & Hero` : ` & Environmental` }</h1>
            <div className="catPageSocials-container">
                { media && socials || <h1>Loading ...</h1> }
            </div>
            <Link to='/allSocials' className="viewAll-button">View All ({count})</Link>
        </div>
    )
}